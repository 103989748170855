<template>
  <div
    :class="{ 'ml-auto': !logged || !user }"
    class="header__user"
  >
    <div
      v-if="!logged || !user"
      class="ml-auto"
      style="width: 5rem"
    >
      <router-link
        to="/user/login"
        class="button button--blank !px-4 h-2 !text-xs !capitalize ml-auto right-0 top-0 mt-2"
      >
        Entrar
      </router-link>
    </div>

    <div
      v-else
      class="dropdown_wrapper mr-2"
    >
      <ui-dropdown position="right">
        <figure
          slot="action"
          class="avatar mobile:justify-end"
        >
          <img
            :src="returnPx"
            :style="returnProfileImage"
            class="w-8 rounded-full mr-4 bg-neutral bg-cover"
          >
          <figcaption class="hidden md:block whitespace-no-wrap w-24 truncate">
            {{ user.name }}
          </figcaption>

          <div class="ml-auto">
            <i class="icon icon-caret" />
          </div>
        </figure>

        <ul
          slot="target"
          class="white"
        >
          <li
            v-for="(route, index) in topMenu"
            :key="`menu-item-${index}`"
          >
            <router-link :to="{ name: route.route }">
              {{ route.label }}
            </router-link>
          </li>

          <li class="border-t border-neutral mt-2 pt-2 text-right">
            <router-link to="/user/logout">
              <strong>Logout</strong>

              <ui-icon
                name="logout"
                class="float-right ml-2 mt-1"
              />
            </router-link>
          </li>
        </ul>
      </ui-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UiDropdown from '@/components/ui/Dropdown.vue';
import UiIcon from '@/components/ui/Icon.vue';

export default {
  name: 'UserMenu',

  components: {
    UiDropdown,
    UiIcon,
  },

  data() {
    return {
      topMenu: [
        {
          label: 'Minha Conta',
          route: 'UserProfile',
        },
        {
          label: 'Agendar Consulta',
          route: 'UserScheduling',
        },
        {
          label: 'Meus Médicos',
          route: 'MyDoctors',
        },
        {
          label: 'Próximos Agendamentos',
          route: 'UserAppointments',
        },
        {
          label: 'Pagamento',
          route: 'UserPayment',
        },
        {
          label: 'Histórico Médico',
          route: 'UserMedicalHistory',
        },
        // {
        //   label: 'Ajuda',
        //   route: 'ajuda',
        // },
      ],
    };
  },

  computed: {
    ...mapGetters('user', ['logged', 'user']),

    returnPx() {
      return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
    },

    returnProfileImage() {
      return { backgroundImage: `url('${this.user.profileImage}')` };
    },
  },
};
</script>
