<style lang="scss" scoped>
._dropdown>label {
  @apply relative mt-1;
  &.right {
    right: 0;
  }
  >input {
    @apply hidden;
  }
  .dropdown-action {
    @apply cursor-pointer;
  }
  .dropdown-target {
    @apply  opacity-0 mt-1 z-20 overflow-hidden;
    transition: all 200ms;
    max-height: 0;
    @screen mobile {
      @apply hidden;
    }
  }
  >input:checked+div {
    @apply  opacity-100 ;
    max-height: 260px ;
    @screen mobile {
      @apply block;
      max-height: 320px;
    }
  }
}
</style>

<template>
  <div
    v-click-outside="()=>dropdownCollapse = false"
    class="_dropdown"
  >
    <label for="dropdown">
      <div class="dropdown-action">
        <slot name="action" />
      </div>
      <input
        id="dropdown"
        v-model="dropdownCollapse"
        type="checkbox"
      >
      <div
        class="dropdown-target"
        :class="position"
        @click="dropdownCollapse = false"
      >
        <slot name="target" />
      </div>
    </label>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';

export default {
  props: {
    position: {
      type: String,
      default: 'left',
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      dropdownCollapse: false,
    };
  },
};
</script>
