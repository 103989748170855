<template>
  <header class="header">
    <div class="header__logo">
      <router-link :to="{ name: headerLink }">
        <img
          :alt="partner.name"
          :src="require(`@/assets/img/logo/${partner.logo.header}`)"
          class="header__img"
        >
      </router-link>
    </div>

    <ul
      v-show="logged"
      class="header__menu"
    >
      <li
        v-for="section in topMenu"
        :key="section.label"
        class="header__menu-item"
      >
        <router-link
          v-if="section.show"
          :to="{ name: section.route }"
        >
          {{ section.label }}
        </router-link>
      </li>
    </ul>

    <user-menu v-if="logged" />
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import UserMenu from '@/components/user/ui/menu.vue';

export default {
  name: 'UserHeader',

  components: {
    UserMenu,
  },

  props: {
    hasLoaded: {
      type: Boolean,
      default: () => true,
    },
  },

  data() {
    return {
      topMenu: [
        {
          label: '% Desconto Farmácia',
          route: 'UserPharmacyDiscount',
          show: false,
        },
      ],
    };
  },

  computed: {
    ...mapGetters('partner', ['partner']),

    ...mapGetters('user', ['logged']),

    headerLink() {
      return this.logged ? 'UserAccount' : 'LandingPage';
    },
  },

  mounted() {
    this.topMenu.find((item) => item.route === 'UserPharmacyDiscount')
      .show = this.partner.hasIntegrationPartners;
  },
};
</script>
