<template>
  <article class="user">
    <user-header />

    <transition
      name="fade"
      mode="out-in"
    >
      <router-view
        @reload="fetchUser"
        class="view"
      />
    </transition>

    <transition
      name="fade"
      mode="out-in"
    >
      <loader v-if="ui.isLoading" />
    </transition>
  </article>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import profile from '@/api/user/profile';
import userLogin from '@/api/user/login';
import handleError from '@/mixins/handleError';
import UserHeader from '@/components/user/ui/header.vue';
import Loader from '@/components/ui/Loader.vue';

export default {
  name: 'UserIndex',

  mixins: [handleError],

  components: {
    UserHeader,
    Loader,
  },

  data() {
    return {
      ui: {
        isLoading: false,
        wasLogged: false,
      },
      refresh: {
        tick: false,
      },
      profileImage: null,
    };
  },

  computed: {
    ...mapGetters('partner', ['partner']),
    ...mapGetters('user', ['logged', 'user']),
  },

  watch: {
    logged: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value) {
          this.validateLogin();
        }
      },
    },
  },

  created() {
    this.ui.wasLogged = Boolean(this.logged);
  },

  methods: {
    ...mapActions('user', ['setUser', 'clearAuth']),
    ...mapActions('ui', ['toggleProgressBar']),

    getLoginValidation() {
      return new Promise((resolve, reject) => {
        const login = userLogin();
        login
          .validateLogin(this.logged)
          .then(() => resolve())
          .catch(() => reject());
      });
    },

    fetchUser() {
      this.ui.isLoading = true;
      this.profile = profile(this.$store.getters['user/headers']);

      this.profile
        .fetch()
        .then((res) => {
          this.profile
            .fetchPicture()
            .then((v) => {
              if (v) {
                res.profileImage = `${
                  process.env.VUE_APP_BASE_API
                }/s3/file/user/${
                  res.id
                }/profile_picture?${
                  Date.now()
                }`;
              }
              // delete res.cpf;
              delete res.rg;
              this.setUser(res);
            });
        })
        .finally(() => {
          this.$store.dispatch('ui/toggleProgressBar', false);
          this.ui.isLoading = false;
        });
    },

    validateLogin() {
      this.ui.isLoading = true;
      this.toggleProgressBar();

      this.getLoginValidation()
        .then(() => this.fetchUser())
        .catch(() => {
          if (this.ui.wasLogged) {
            this.$router.push({ name: 'UserLogout' });
          }
        })
        .finally(() => {
          this.ui.isLoading = false;
          this.toggleProgressBar(false);
        });
    },
  },
};
</script>

<style lang="scss">
body {
  @apply bg-gray-50 #{!important};

  @screen md {
    @apply bg-gray-50 #{!important};
  }
}

.user-section {
  @apply flex flex-col items-center justify-center;
  @apply max-w-screen-lg w-full p-6 mb-6 pb-10 mx-auto;

  @screen md {
    @apply shadow-xl rounded-4xl bg-white mt-8 ;
  }

  h1 {
    @apply text-2xl;

    @screen md {
      @apply text-3xl;
    }
  }

  @screen sm {
    @apply mx-3 pt-0;
  }
}

.user {
  @apply box-content;
  @apply w-full max-w-screen-xl;
  @apply flex flex-col items-center;
  @apply m-auto;

  .header {
    @apply relative justify-start;

    @screen sm {
      @apply justify-between;
    }

    &__menu {
      @apply ml-6;
    }

    &__user {

      .dropdown_wrapper {
        @apply top-0 right-0 mt-1 py-2 px-4 absolute bg-white rounded-xl shadow-md z-30;

        .avatar {
          @apply flex flex-no-wrap text-secondary items-center;
        }

        ul {
          @apply pt-2 px-2;

          li {
            @apply my-2;
          }
        }
      }
    }
  }
  .view {
    @apply px-2;

    @screen md {
      padding-left: 8rem;
      padding-right: 8rem;
      @apply w-full pt-8;
    }
  }
}

.header__logo,
.header__menu,
.heder__user {
  order: unset;
}

.header__menu {
  margin-left: auto !important;
}
</style>
